import React from "react";
import About from "../../components/admin/about/About";

function AboutPage() {
	return (
		<div>
			<p className="text-center text-2xl m-4"> About </p>
			<About />
		</div>
	);
}

export default AboutPage;
