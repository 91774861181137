import React from "react";
import Services from "../../components/admin/services/Services";

function ServicePage() {
	return (
		<div>
			<p className="text-center text-2xl m-4"> Services </p>
			<Services />
		</div>
	);
}

export default ServicePage;
