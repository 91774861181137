import React from "react";
import Photos from "../../components/admin/photos/Photos";

function PhotosPage() {
	return (
		<div>
			<p className="text-center text-2xl m-4"> Photos </p>
			<Photos />
		</div>
	);
}

export default PhotosPage;
