import React from "react";
import Videos from "../../components/admin/videos/Videos";

function VideosPage() {
	return (
		<div>
			<p className="text-center text-2xl m-4"> Videos </p>
			<Videos />
		</div>
	);
}

export default VideosPage;
