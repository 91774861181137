import React from "react";
import Sliders from "../../components/admin/sliders/Sliders";

function SliderPage() {
	return (
		<div>
			<p className="text-center text-2xl m-4"> Sliders </p>
			<Sliders />
		</div>
	);
}

export default SliderPage;
